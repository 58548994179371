import { Injectable } from '@angular/core';
import {ApiService} from './services/api.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
      public api:ApiService
  ) { }

    delete(url,id) {
        return this.api.delete("api/v1/"+url+'/'+id)
    }

  addJob(data: any,type) {
    return this.api.post('v1/'+type, data)
  }
}
