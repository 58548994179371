import { Component, OnInit } from '@angular/core';
import {ReportsService} from '../services/reports.service';
import {MasterDetailService} from '../services/master-detail.service';
import * as moment from 'moment';
import {LoadingController, ModalController, ToastController} from '@ionic/angular';

@Component({
  selector: 'app-gas-monitor-edit',
  templateUrl: './gas-monitor-edit.page.html',
  styleUrls: ['./gas-monitor-edit.page.scss'],
})
export class GasMonitorEditPage implements OnInit {
  date: any;
  airspace_gas_concentration: any;
  comments: any;
  p_temp: number;
  product_gas_concentration: any;
  humidity: number;
  jobselected: any;
  public jobs: any;
  public stacks: any;
  public stacksselected: number ;
  a_temp: number;
  public data: any;
  public loading: any;
  public stackss: any;

  constructor(
      public  report:ReportsService,
      public modalCtrl:ModalController,
      public ms:MasterDetailService,
      public toastCtrl:ToastController,
      public loadingCtrl:LoadingController,

  ) {



     this.data = ms.getValue().attributes

      this.stacksselected = Number(this.data.stack_id)
      this.date= moment(this.data.date +" "+ this.data.time).format('YYYY-MM-DD HH:mm')
      this.a_temp =this.data.ambient_temparature
      this.p_temp = this.data.product_temparature
      this.humidity = this.data.humidity
      this.product_gas_concentration =this.data.product_gas_concentration
      this.airspace_gas_concentration = this.data.airspace_gas_concentration
      this.comments = this.data.comments






  }

  ngOnInit() {

    this.loadStackss()
  }


  submit() {
    this.loader()
    let  data = {
      "data": {
        "type": "stackmonitors",
        "id":this.ms.getValue().id,
        "attributes": {
          "stack_id": this.stacksselected,
          "date":  moment(this.date).format('YYYY-MM-DD'),
          "time": moment(this.date).format('HH:mm'),
          "ambient_temparature": this.a_temp,
          "product_temparature": this.p_temp,
          "humidity": this.humidity,
          "product_gas_concentration": this.product_gas_concentration,
          "airspace_gas_concentration": this.airspace_gas_concentration,
          "comments": this.comments,
        }

      }
    }
    console.log(JSON.stringify(data))

    this.report.editGas(this.ms.getValue().id,data).subscribe(res=>{
      this.presentToast('Saved');

      this.closeModal()
      this.loading.dismiss()
    },error1 => {
      this.presentToast('Failed Please try again');
      this.loading.dismiss()
    })
  }


  async loader(){
    this.loading =  await this.loadingCtrl.create({
      message: 'Please Wait',
      duration: 9000
    });
    this.loading.present();
  }



  async presentToast(id) {
    const toast = await this.toastCtrl.create({
      message: id,
      duration: 3000,

      position:'top',
    });
    toast.present();
  }


  loadStackss(){
    this.report.queryJobs('stacks').subscribe((res:any)=>{
      this.stacks = res.data

      let data =[]
      for (let d of  this.stacks){
        let p
        p={
          id:Number(d.id),
          job: "Job #"+d.attributes.job_id+" - Stack #"+d.attributes.stack_no
        }
        data.push(p)
      }
      this.stackss = data
      console.log(this.stackss)

    },error1 => {

    })
  }


  closeModalX() {
    this.modalCtrl.dismiss({data:"d"})

  }

  toNum(id){
    return Number(id)
  }

  closeModal(){
    this.modalCtrl.dismiss({data:"s"})
  }

  log() {
    console.log(this.stacksselected)
  }
}
